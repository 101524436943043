<template>
    <div>
        <div class="sub">
            <div class="chart-title">FISH COMMUNITY</div>
            <div class="clearfix">
                <div style="float: left; width: 60%;">
                    <div>
                        <canvas class="chart" id="pie-chart"></canvas>
                    </div>
                </div>
                <div style="float: left; width: 40%;">
                    <div style="padding: 10px">
                        <div v-for="label in labels" :key="label" class="legend">
                            <div style="float: left;" class="square-large" :style="{ backgroundColor: getColorByFishClass(label)}"></div>
                            <div style="float: left;">{{ prettifyFishName(label) }}</div>
                            <div style="clear: both;"></div>
                        </div>

                    </div>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>
</template>

<script>
import { getColorByFishClass } from '@/assets/js/fishUtils.js'

export default {
    name: 'FishCommunityCard',
    props: ['fish_community'],
    data() {
        return {
        data: [],
        labels: [],
        };
    },
    computed: {
        // Your computed properties here
    },
    mounted() {
        this.initPieChart();
    },
    methods: {
        getColorByFishClass,
        prettifyFishName(name) {
            return name
                .replace(/_/g, ' ')
                .replace(/\b\w/g, l => l.toUpperCase());
        },
        initPieChart() {
            this.data = Object.values(this.fish_community);
            this.labels = Object.keys(this.fish_community);
            const backgroundColor = this.labels.map(label => getColorByFishClass(label));
            
            let ctx = document.getElementById('pie-chart').getContext('2d');
            this.pie_chart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: ['Percent'],
                    datasets: [
                        {
                            data: this.data,
                            backgroundColor: backgroundColor,
                            borderWidth: 0,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    cutoutPercentage: 75,
                    tooltips: {
                        enabled: false,
                    },
                }
            });
        },
  },
};
</script>

<style scoped>

.chart-title {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    padding: 5px;
    text-align: left;
}
.sub-title {
    color: var(--primary);
    font-size: 20px;
}

.sub {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #1F1F1F;
    font-size: 14px;
    padding: 8px;
    font-family: 'Riposte', sans-serif;
}

.sub-number {
    font-size: 28px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
}

.sub-unit {
    font-size: 12px;
}

.chart {
    /* margin-bottom: 10px; */
    padding: 10px;
}

.square {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 10px;
}

.legend {
    margin-left: 5px;
    font-size: 12px;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.square-large {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-right: 10px;
}


.legend {
    font-size: 14px;
    margin-bottom: 5px;
}
</style>
