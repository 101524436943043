<template>
    <div>
        <div class="sub">
            <div class="chart-title">REEF HEALTH SCORE</div>
            <div class="gauge-wrapper">
                <div class="gauge-container">
                    <div id="gauge-chart" class="gauge-chart" style="width: 200px; height: 100px;"></div>
                    <div class="gauge-text">
                        <div class="score">{{ score }}</div>
                        <div class="comment" :style="{ color: fontColor }">{{ comment }}</div>
                        <div class="time">{{ formatTime(reef.health_score.time) }}</div>
                    </div>
                </div>
            </div>
            <div class="progress-container">
                <div v-for="(item, index) in items" :key="index" class="progress-item">
                <div class="progress-label">
                    <span class="progress-value">{{ item.value }} <span class="progress-unit">{{ item.unit }}</span></span><br>
                    <span class="progress-name">{{ item.label }}</span>
                </div>
                <div :id="'progress-' + item.label" class="progress-bar"></div>
                </div>
            </div>
            <!-- <div class="gauge-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.
            </div> -->
        </div>
        <!-- <div class="level is-mobile" style="margin-bottom: 0px; margin-top: 10px;">
            <div class="level-left">
                <div class="level-item name">
                    Reef Health Score
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <div @click="openOverall = !openOverall" style="cursor: pointer">
                        <b-icon :icon="openOverall ? 'menu-down' : 'menu-left'"></b-icon>
                    </div>
                </div>
            </div>
        </div>
        <b-collapse v-model="openOverall">
            <div class="sub">
                <div class="chart-title">REEF HEALTH SCORE</div>
                <canvas class="chart-wrapper" id="overall-chart"></canvas>
            </div>
        </b-collapse> -->
    </div>
</template>

<script>
import Chart from 'chart.js'
import moment from 'moment';
const { InfluxDB, FluxQuery, flux } = require('@influxdata/influxdb-client')
const token = '1Mb6JnkdlVyRrVb7JaccrOOgt-7jWgkQNIrpU_iJtGm_6CNeLt_WlABvfMfKR1JHzSC7nZb75HJraBRxAkYhpQ=='
const client = new InfluxDB({ url: 'https://us-central1-1.gcp.cloud2.influxdata.com', token: token })
const queryApi = client.getQueryApi('ReefOS')

export default {
    name: 'HealthCard',
    props: ['reef'],
    data() {
        return {
            health_gauge: null,
            overall_chart: null,
            openOverall: true,
            items: [
                { label: 'Fish', value: 90, unit: '%', color: 'grey' },
                { label: 'Coral', value: 10, unit: '%', color: 'grey' },
                { label: 'Algae', value: 50, unit: '%', color: 'grey' },
                { label: 'Temp', value: 27, unit: '°c', color: 'grey' },
            ],
            gradients: [
                { 
                    label: 'Fish', 
                    value: 
                        `<defs>
                            <linearGradient id="Fish" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                            <stop offset="0%" stop-color="red"/>
                            <stop offset="50%" stop-color="yellow"/>
                            <stop offset="100%" stop-color="green"/>
                            </linearGradient>
                        </defs>`
                },
                {
                    label: 'Coral',
                    value:
                        `<defs>
                            <linearGradient id="Coral" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                            <stop offset="0%" stop-color="red"/>
                            <stop offset="50%" stop-color="yellow"/>
                            <stop offset="100%" stop-color="green"/>
                            </linearGradient>
                        </defs>`
                },
                {
                    label: 'Algae',
                    value:
                        `<defs>
                            <linearGradient id="Algae" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                            <stop offset="0%" stop-color="green"/>
                            <stop offset="50%" stop-color="yellow"/>
                            <stop offset="100%" stop-color="red"/>
                            </linearGradient>
                        </defs>`
                },
                {
                    label: 'Temp',
                    value:
                        `<defs>
                            <linearGradient id="Temp" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                            <stop offset="0%" stop-color="green"/>
                            <stop offset="50%" stop-color="yellow"/>
                            <stop offset="100%" stop-color="red"/>
                            </linearGradient>
                        </defs>`
                },
            ]
        }
    },
    mounted() {
        this.initHealthGauge();
        // this.initMetrics();
        this.updateScores();
        // this.getData();
    },
    watch: {
        reef: function (new_reef) {
            this.health_gauge ? this.health_gauge.destroy() : '';
            this.updateScores();
            this.$nextTick(() => {
                // this.getData();
            });
        }
    },
    computed: {
        score() {
            return this.reef.health_score.overall_score.toFixed();
        },
        comment() {
            if (this.score <= 33) return 'Poor';
            else if (this.score <= 66) return 'Fair';
            else return 'Good';
        },
        fontColor() {
            return this.getColorFromGradient(this.score);
        },
    },
    methods: {
        formatTime(time) {
            const formatted = new Date(time).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true }).replace(',', '').replace(' ', ' ').replace(/\//g, '/');
            return formatted;
        },
        async updateScores() {
            
            // Update Fish values
            let fishItem = this.items.find(item => item.label === 'Fish');
            fishItem.value = this.reef.health_score.fish_score.toFixed();
            fishItem.color = this.getColorFromGradient(fishItem.value);

            // Update Coral values
            let coralItem = this.items.find(item => item.label === 'Coral');
            coralItem.value = this.reef.health_score.coral_score.toFixed();
            coralItem.color = this.getColorFromGradient(coralItem.value);

            // Update Algae values
            let algaeItem = this.items.find(item => item.label === 'Algae');
            algaeItem.value = 100 - this.reef.health_score.coral_score.toFixed();
            algaeItem.color = this.getColorFromGradient(100-algaeItem.value);

            // Update Temp values
            let tempItem = this.items.find(item => item.label === 'Temp');
            tempItem.value = await this.getTemp();
            let val = tempItem.value;
            if (val >= 30) val = 1;
            else if (val <= 27) val = 99;
            else val = 100 - ((val - 27) / 3) * 100;
            tempItem.color = this.getColorFromGradient(val);
            
            this.initMetrics();
        },
        getTemp() {
            return new Promise((resolve, reject) => {
                let temp = [];
                const query = flux`from(bucket: "${this.reef.reefos_id}")
                    |> range(start: -7d)
                    |> filter(fn: (r) => r["_measurement"] == "sensors")
                    |> filter(fn: (r) => r["_field"] == "externalTemperature")
                    |> aggregateWindow(every: 7d, fn: mean, createEmpty: false)
                    |> yield(name: "mean")`
                queryApi.queryRows(query, {
                    next: (row, tableMeta) => {
                        const o = tableMeta.toObject(row);
                        temp.push(o._value)
                    },
                    error: (error) => {
                        console.error(error);
                    },
                    complete: () => {
                        let average = temp.reduce((acc, val) => acc + val, 0) / (temp.length || 1);
                        resolve(average.toFixed());
                    },
                });
            });
        },
        remapTemp(value) {
            if (value <= 25) return 10 / 100;
            if (value >= 30) return 99 / 100;
            const heightPercentage = ((value - 25) / 5);
            return heightPercentage;
        },
        getColorFromGradient(value) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = 100;
            canvas.height = 1;
            const gradient = ctx.createLinearGradient(0, 0, 100, 0);
            gradient.addColorStop(0, 'red');
            gradient.addColorStop(0.5, 'yellow');
            gradient.addColorStop(1, 'green');
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, 100, 1);

            const imageData = ctx.getImageData(value, 0, 1, 1).data;
            const rgbaColor = `rgba(${imageData[0]}, ${imageData[1]}, ${imageData[2]}, ${imageData[3] / 255})`;
            return rgbaColor;
        },
        initHealthGauge() {
            var bar = new ProgressBar.SemiCircle('#gauge-chart', {
                strokeWidth: 10,
                easing: 'easeInOut',
                duration: 1400,
                color: 'url(#gradient)',
                trailColor: '#444444',
                trailWidth: 10,
                svgStyle: {
                    'height': '120px',
                    'width': '200px',
                    'stroke-width': '10',
                    'strokeLinecap': 'round',
                },
            });
            
            bar.animate(this.score / 100);  // Number from 0.0 to 1.0

            let linearGradient = `
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                <stop offset="0%" stop-color="red"/>
                <stop offset="50%" stop-color="yellow"/>
                <stop offset="100%" stop-color="green"/>
                </linearGradient>
            </defs>
            `
            bar.svg.insertAdjacentHTML('afterBegin', linearGradient);

        },
        initMetrics() {
            this.items.forEach(item => {
                let bar = new ProgressBar.Line('#progress-' + item.label, {
                    strokeWidth: 3,
                    easing: 'easeInOut',
                    duration: 1400,
                    color: `url(#${item.label})`, 
                    trailColor: '#444444',
                    trailWidth: 5,
                    svgStyle: {
                        'width': '35px',
                        'height': '3px',
                        'stroke-width': '3',
                        'strokeLinecap': 'round',
                        transform: 'rotate(-90deg) translateY(0%)',
                    },
                });
                if (item.label === 'Temp') {
                   bar.animate(this.remapTemp(item.value));
                } else {
                    bar.animate(item.value / 100);
                }
                bar.svg.insertAdjacentHTML('afterBegin', this.gradients.find(g => g.label === item.label).value);
            });
        },
        getData() {
            let fish_score = [];
            let coral_score = [];
            let overall_score = [];
            let timestamps = [];
            let weekdays = ['S', 'M', 'T', 'W', 'Th', 'F', 'S'];
            const query = flux`from(bucket: "${this.reef.reefos_id}")
                |> range(start: -7d)
                |> filter(fn: (r) => r["_measurement"] == "health_score")
                |> filter(fn: (r) => r["_field"] == "coral_score" or r["_field"] == "fish_score" or r["_field"] == "overall_score")
                |> aggregateWindow(every: 1d, fn: mean, createEmpty: false)
                |> yield(name: "mean")`
            queryApi.queryRows(query, {
                next: (row, tableMeta) => {
                    const o = tableMeta.toObject(row);
                    if (o._field === "coral_score") {
                        coral_score.push(o._value);
                    } else if (o._field === "fish_score") {
                        fish_score.push(o._value);
                    } else if (o._field === "overall_score") {
                        overall_score.push(o._value);
                        timestamps.push(new Date(o._time));
                    }
                    
                },
                error: (error) => {
                    console.error(error);
                },
                complete: () => {
                    if (overall_score.length > 0) {
                        this.initOverallScoreChart(overall_score, timestamps);
                    }},
            });

        },
        initOverallScoreChart(overall_score, timestamps) {
            this.overall_chart ? this.overall_chart.destroy() : '';
            let ctx = document.getElementById('overall-chart').getContext("2d");
            this.overall_chart = new Chart(ctx, {
                type: 'line',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            position: 'bottm',
                            ticks: {
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: true,
                                maxTicksLimit: 7,
                                maxRotation: 0,
                                callback: function (tick, index, ticks) {
                                    if (index === 0 || index === ticks.length - 1) {
                                        return moment(tick).format('M/D/YY');
                                    }
                                    // Middle tick
                                    const middleIndex = Math.floor(ticks.length / 2);
                                    if (index === middleIndex) {
                                        return moment(tick).format('M/D/YY');
                                    }
                                    return null;
                                },
                            },
                            gridLines: {
                                display: false,
                                drawBorder: true,
                                color: '#545454',

                            },
                        }],
                        yAxes: [{
                            display: true,
                            position: 'left',
                            ticks: {
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: true,
                                maxTicksLimit: 6,
                                // suggestedMin: 0,
                                callback: function (value, index, array) {
                                    return value + '  ';
                                }
                            },
                            gridLines: {
                                display: true,
                                drawBorder: false,
                                drawOnChartArea: true,
                                color: '#545454',
                            }
                        }],
                    },
                    tooltips: {
                        displayColors: false,
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 0;
                        },
                        callbacks: {
                            label: function (tooltipItem, data) {
                                return (tooltipItem.yLabel + ' %');
                            },
                            title: function (tooltipItem, data) {
                                return tooltipItem[0].xLabel;
                            }
                        }
                    }
                },
                data: {
                    labels: timestamps,
                    datasets: [
                        {
                            label: '%',
                            pointRadius: 4,
                            borderColor: '#11ADE3',
                            pointBackgroundColor: '#1B5366',
                            borderWidth: 2,
                            data: overall_score,
                        },
                    ],
                }
            });
        },
    }
}
</script>

<style scoped>

.progress-container {
  display: flex;
  width: 100%;
  padding: 10px;
  margin-left: 10px;
}

.progress-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25%;
  height: 40px;
}
.progress-value {
  font-size: 22px;
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  margin-top: 3px;
}

.progress-bar {
    margin-bottom: 7px;
}

.progress-unit {
    font-size: 10px; /* Adjust to your preference */
    font-family: 'Lato', sans-serif;
    margin-left: -3px;
}

.progress-name {
  font-size: 1em;
  color: #8C8C8C;
}
.gauge-wrapper {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}
.gauge-container {
    position: relative;
    width: 200px;
    height: 150px;
}

.gauge-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    text-align: center;
}

.score {
    font-size: 42px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
}

.comment {
    font-size: 1em;
    margin-top: 5px;
    font-weight: 900;
}
.time {
    font-size: 0.8em;
    margin-top: 5px;
    font-weight: 900;
    color: #8C8C8C;
}

.chart-title {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    padding: 5px;
    text-align: left;
}
.chart-wrapper {
    width: 340px;
    height: 250px !important;
    padding: 10px;
    border-radius: 4px;
}
.sub {
    margin-left: 10px;
    margin-right: 10px;
}
</style>