<template>
    <div class="reef-wrapper">
        <Close />
        <div v-if="selectedOutplantSite">
            <div class="level is-mobile title">
                <div class="level-left">
                    <div class="level-item">
                        <div>
                            {{ selectedOutplantSite.name.toUpperCase() }} <br>
                            <p class="sub-title">OUTPLANT SITE</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectedOutplantSite.reefos_id" class="level is-mobile"
                style="margin-top: -15px; margin-bottom: 5px; color: #27BDF4; cursor: pointer;"
                @click="$router.push({ path: 'sensors' });">
                <div class="level-left">
                    <div class="level-item">
                     
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="link-text">Linked ReefOS Device</div>
                    </div>
                    <div class="level-item" style="margin-right: 15px; margin-left: -10px;">
                        <b-icon icon="chevron-right"></b-icon>
                    </div>
                </div>
            </div>
            <!-------------------------- End title ------------------------>
            <div>
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-left: 20px;">
                            <div style="float: left;">Corals Outplanted</div><br>
                            <div class="sub-number">{{ selectedOutplantSite.stats.total_corals }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-right: 20px;">
                            <div style="float: left;">Coral Species</div><br>
                            <div class="sub-number">{{ selectedOutplantSite.stats.total_coral_species }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-left: 20px;">
                            <div style="float: left;">Avg. Corals/Cell</div><br>
                            <div class="sub-number">{{ Math.floor(selectedOutplantSite.stats.mean_corals_per_cell) }}
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-right: 20px;">
                            <div style="float: left;">Avg. Species/Cell</div><br>
                            <div class="sub-number">{{ Math.floor(selectedOutplantSite.stats.spp_per_cell) }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-------------------------- End boxes ------------------------>
            <!-- <div class="level-wrapper"> -->
            <!---------------------------- health card ---------------->
            <!-- <HealthCard :reef="selectedReef" v-if="selectedReef.reefos_id"/> -->
            <!---------------------------- fish community card -------->
            <!-- <FishCommunityCard :fish_community="selectedReef.fish_community_fractions" v-if="selectedReef.reefos_id"/> -->
            <!---------------------------- coral growth ---------------->
            <!-- <div class="level is-mobile" style="margin-bottom: 0px; margin-top: 10px;">
                    <div class="level-left">
                        <div class="level-item name">
                            Coral Growth
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div @click="openGrowth = !openGrowth" style="cursor: pointer">
                                <b-icon :icon="openGrowth ? 'menu-down' : 'menu-left'"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <b-collapse v-model="openGrowth">
                    <div class="sub">
                        <div class="chart-title">CORAL GROWTH (%)</div>
                        <canvas class="chart-wrapper" id="growth-chart"></canvas>
                    </div>
                </b-collapse> -->
            <!-- </div> -->
        </div>
        <div v-else>
            <div class="sub">
                To view reef restoration details, please select a reef from the map
                <b-icon icon="map" size="is-small"></b-icon>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js';
import { eventBus } from '@/events.js'
import Close from '@/components/utils/Close.vue'
import HealthCard from '@/components/cards/HealthCard.vue'
import FishCommunityCard from '@/components/cards/FishCommunityCard.vue'

export default {
    name: 'OutplantSites',
    props: ['selectedOutplantSite'],
    components: {
        Close,
        HealthCard,
        FishCommunityCard,
    },
    data() {
        return {
        }
    },
    methods: {
        closeSidebar() {
            eventBus.$emit('closeSidebar');
        },
    },
}
</script>

<style scoped>

.link-text {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    text-decoration: underline;
}
.chart-wrapper {
    padding: 10px;
    border-radius: 4px;
    max-height: 200px;
}

.reef-wrapper {
    font-family: 'Lato', sans-serif;
}

.level-wrapper {
    font-size: 16px;
    font-family: 'Riposte', sans-serif;
    padding: 10px;
}

.title {
    /* font-family: 'Office-Code-Pro', monospace; */
    font-weight: 800;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 20px;
    text-align: left;
    font-size: 36px;
    color: white;
}

.sub-title {
    color: var(--primary);
    font-size: 20px;
}

.sub {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #1F1F1F;
    font-size: 14px;
    padding: 8px;
    font-family: 'Riposte', sans-serif;
}

.sub-number {
    font-size: 28px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
}

.sub-unit {
    font-size: 12px;
}

.chart-title {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    padding: 5px;
}

.square {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 10px;
}

.legend {
    margin-left: 5px;
    font-size: 12px;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
</style>